import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

export const SectionsContainer = styled.div`
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  overflow: scroll;
  position: relative;
`

export const SectionsScrolledWrap = styled.div`
  width: 100%;
  height: 100%;
  overflow: scroll;
  position: relative;

  @media (max-width: 768px) {
    overflow-x: hidden;
    width: 100%;
  }
`
export const StyledSection = styled.section.attrs(({ align, height, backgroundImage }) => ({
  style: {
    alignItems: align || 'center',
    height: `${height}px`,
    backgroundImage: backgroundImage ? `url(${backgroundImage})` : 'none',
  }
}))`
  background-size: cover;
  width: 100vw;
  position: relative;
  display: flex;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  white-space: pre-line;
  z-index: 1;
  height: ${({height}) => `${height}px`};

  @media (max-width: 768px) {
    min-height: ${({minHeight}) => minHeight ? `${minHeight}px !important` : '395px'};
    height: ${({minHeight}) => minHeight ? `${minHeight}px !important` : 'fit-content'};
  }

  @media (max-width: 450px) {
    min-height: ${({ minHeight }) => minHeight ? `${minHeight}px !important` : '560px'};
    height: ${({ minHeight }) => minHeight ? `${minHeight}px !important` : 'fit-content !important' };
  }

  @media (min-width: 1440px) {
    height: ${({maxHeight, height}) => maxHeight ? `${maxHeight} !important` : `${height}px`};
  }
`

export const ColoredBackground = styled.div.attrs(({ backgroundColor }) => ({
  style: {
    background: backgroundColor,
  }
}))`
  --image: url('../../assets/images/change_second_bg.png');
  --opacity: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  
  :before {
    content: "";
    background-image: var(--image);
    background-size: cover;
    background-position: top;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: var(--opacity);
  }

  > div {
    max-width: 90%;
    z-index: 5;
  }

  @media (max-width: 768px) {
    > div {
      max-width: 100%;
    }
  }
`

export const UnColoredBackground = styled.div.attrs(({ backgroundColor }) => ({
  style: {
    background: backgroundColor,
  }
}))`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;

  > div {
    max-width: 90%;
    z-index: 5;
  }

  @media (max-width: 768px) {
    > div {
      max-width: 100%;
    }
  }
`

export const AnimatedSection = (props) => {
  const {
    id,
    Component,
    backgroundColor,
    changeImgOpacity,
    changeImg,
    content,
    align,
    height,
    backgroundImage,
    minHeight,
    maxHeight
  } = props

  const animatedId = `animated-${id}`
  const onlyColoredId = `only-colored-${id}`

  useLayoutEffect(() => {
    const element = document.getElementById(animatedId);
    element?.style.setProperty("--image", `url(${changeImg})`);
   }, [animatedId])

  useLayoutEffect(() => {
    const element = document.getElementById(animatedId);
    element?.style.setProperty("--opacity", changeImgOpacity);
  }, [changeImgOpacity, animatedId])

  return (
    <StyledSection
        key={id}
        id={id}
        align={align}
        height={height}
        minHeight={minHeight}
        maxHeight={maxHeight}
        backgroundImage={backgroundImage}
    >
      {
        changeImg
        ? (
          <ColoredBackground id={animatedId} backgroundColor={backgroundColor}>
            <Component content={content}/>
          </ColoredBackground>
        ) : (
          <UnColoredBackground id={onlyColoredId} backgroundColor={backgroundColor}>
            <Component content={content}/>
          </UnColoredBackground>
        )

      }
    </StyledSection>
  )
}

export const Sections = ({ sections }) => {
  const [changedOpacities, setChangedOpacities] = useState({})

  // Now we have always only 5 sections with changed opacity
  const [first, second, third, fourth, fifth] = useMemo(() => (Object.values(sections)), [sections])

  const sectionsWithData = useMemo(() => (Object.values(sections)).map((el, index) => ({
    changeImgOpacity: changedOpacities[el.id] || 0,
    ...el,
  })), [changedOpacities])

  const handleChangeOpacity = useCallback((upd_first, upd_second, upd_third) => setChangedOpacities({
    [first.id]: upd_first,
    [second.id]: upd_second,
    [third.id]: upd_third,
  }), [first, second, third])

  const trackScrolling = useCallback((e) => {
    const scrolledPx = window.innerHeight + e.target.scrollTop
    if (scrolledPx > first.height) {
      const startedSecond = first.height
      const endedSecond = startedSecond + second.height
      const endedThird = endedSecond + third.height

      const forceAnimation = 1.3
      if (scrolledPx > endedThird) {
        const updateOpacity = ((scrolledPx - endedThird) / fourth.height)
        handleChangeOpacity(1, 1, updateOpacity * forceAnimation)
      } else if (scrolledPx > endedSecond) {
        const updateOpacity = ((scrolledPx - endedSecond) / third.height)
        handleChangeOpacity(1, updateOpacity * forceAnimation, 0)
      } else if (scrolledPx > startedSecond) {
        const updateOpacity = ((scrolledPx - startedSecond) / second.height)
        handleChangeOpacity(updateOpacity * forceAnimation, 0, 0)
      }
    }
  }, [handleChangeOpacity, sectionsWithData])


  useEffect(() => {
    const element = document.getElementById('scroll')

    if (window.innerWidth > 500){
      element.addEventListener("scroll", trackScrolling)
    }

    return () => element.removeEventListener("scroll", trackScrolling)
  }, [trackScrolling])

  return (
    <SectionsContainer>
      <SectionsScrolledWrap id='scroll'>
        {sectionsWithData.map((section) => <AnimatedSection key={section.id} {...section}/>)}
      </SectionsScrolledWrap>
    </SectionsContainer>
  )
}

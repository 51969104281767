import React from 'react'
import {Link} from 'react-router-dom'
import styled from 'styled-components'

import { URLS } from '../../../pages/urls'

const ContentWrap = styled.div`
  display: grid;
  text-align: left;
  height: 100%;
  padding: 53px 0 100px;

  > h1 {
    text-align: center;
  }
  
  > p {
    color: #00EAFA;
    line-height: 39px;
  }
  @media (min-width: 1024px) {
    width: 60%;
  }
  @media (max-width: 983px) {
    padding: 65px;
  }
  @media (max-width: 768px) {
    padding: 10px;
  }
`
const DataRow = styled.div`
  display: flex;
  text-align: left;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`
const DataElement = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: ${({noGrow}) => noGrow ? 0 : 1};
  align-items: flex-start;

  @media (max-width: 768px) {
    width: 100%;
    align-items: center;
  }
`
const DataHeader = styled.h6`
  letter-spacing: 0.1em;
  margin-bottom: 32px;

  @media (max-width: 768px) {
    margin-bottom: 22px;
  }
`
const DataText = styled.p`
  font: normal normal normal 18px/1.4em "BrandonGrotesque","BrandonGrotesque",sans-serif;
  
  > a {
    color: #FFFFFF;
  }
  > a:visited {
    color: #EDEDED;
  }
  > a:hover {
    color: #00EAFA;
  }
  
  @media (max-width: 768px) {
    margin-bottom: 10px;
    padding: 0 20%;
  }
`
const Links = styled.div`
  height: fit-content;
  display: grid;
  line-height: 34px;
  font-size: 19px;
  margin-top: 30px;
  
  > a {
    color: #FFFFFF;
  }

  > a:hover {
    color: #00EAFA;
  }

  @media (max-width: 768px) {
    text-align: left;
    > a {
      font-size: 19px;
    }
  }
`
const Text = styled.p`
  font: normal normal normal 21px/1.4em "Avenir-85-Heavy","Avenir-85-Heavy",sans-serif;
  font-size: 15px;
  margin-top: 24px;
  @media (max-width: 768px) {
    text-align: left;
  }
`
const Header = styled.h3`
  font-size: 31px;
  text-align: center;
  padding-top: 20px;

  @media (max-width: 768px) {
    text-align: center;
    font-size: 19px;
    padding: 0;
  }
`

export const ContactsSection = ({ content }) => {
  const { column_1, column_2, header, text, privacyPolicy, termsAndConditions } = content

  return (
      <ContentWrap>
        <DataRow>
          <DataElement>
            <DataHeader>{column_1.header}</DataHeader>
            <DataText>{column_1.text}</DataText>
          </DataElement>
          <DataElement/>
          <DataElement noGrow>
            <DataHeader>{column_2.header}</DataHeader>
            <DataText>
              <a href={`mailto:${column_2.email}`} target='_blank' rel="noreferrer" >
                {column_2.email}
              </a>
            </DataText>
            <DataText>
              <Link to={column_2.redditLink} target='_blank' rel="noreferrer" >{column_2.redditTextLink}</Link>
            </DataText>
            <DataText>
              <Link to={column_2.linkedInLink} target='_blank' rel="noreferrer" >{column_2.linkedInTextLink}</Link>
            </DataText>
            <DataText>
              <Link to={column_2.instagramLink} target='_blank' rel="noreferrer" >{column_2.instagramTextLink}</Link>
            </DataText>
            <DataText>
              <Link to={column_2.telegramLink} target='_blank' rel="noreferrer" >{column_2.telegramTextLink}</Link>
            </DataText>
          </DataElement>
      </DataRow>
        <Header>{header}</Header>
        <Text>{text}</Text>
        <Links>
          <Link to={URLS.privacyPolicy()} target="_blank" rel="noopener noreferrer">{privacyPolicy}</Link>
          <Link to={URLS.conditions()} target="_blank" rel="noopener noreferrer">{termsAndConditions}</Link>
        </Links>
      </ContentWrap>
  )
}

import React from 'react'

import { Sections } from '../../components/sections/index'
import { affiliatesSectionsUI } from '../../assets/content/info/texts'

const Affiliates = () => {
  return <Sections sections={affiliatesSectionsUI} />
}

export default Affiliates

import React from 'react'
import styled from 'styled-components'

const ContentWrap = styled.div`
  flex-direction: column;
  display: flex;
  justify-items: center;
  align-items: center;
  height: 65%;

  @media (max-width: 768px) {
    height: 100%;
  }
`
const Title = styled.h3`
  letter-spacing: 0.1em;
  text-align: center;
  margin-bottom: 28px;
  font-size: 32px;
  line-height: 1.4em;
  color: #00EAFA;

  @media (max-width: 425px) {
    font-size: 21px;
  }
`
const Subtitle = styled.h5`
  text-align: center;
  width: 100%;
  font-size: 34px;

  @media (max-width: 768px) {  
    margin-top: 0;
    line-height: 26px;
    font-size: 30px;
  }

  @media (max-width: 425px) {
    font-size: 21px;
  }
`
const FooterText = styled.p`
  text-align: center;
  line-height: 30px;
  width: 100%;
  margin-top: 90px;
  font-size: 34px;

  @media (max-width: 768px) {
    font-size: 30px;
  }

  @media (max-width: 425px) {
    font-size: 22px;
    margin-top: 20px;
  }
`

const ListWrap = styled.div`
  flex-direction: column;
  display: flex;
  align-self: center;
`
const ListItem = styled.div`
  display: flex;
  align-items: baseline;
  gap: 10px;
`
const ListItemText = styled.p`
  line-height: 44px;
  width: 100%;
  font-size: 34px;

  @media (max-width: 768px) {
    font-size: 30px;
    width: fit-content;
  }

  @media (max-width: 425px) {
    font-size: 21px;
  }
`

const MobileColored = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  
  @media (max-width: 768px) {
    width: 100%;
    background-color: rgb(14, 32, 150, 0.7);
    padding: 20px;
    margin: 30px 0;
    height: 70%;
  }

  @media (max-width: 425px) {
    justify-content: flex-start;
    margin: 8% 0;
  }
`

const Dot = styled.span`
  background-color: #FC26BC;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.6);
`

export const AboutSection = ({ content }) => {
  const {title, subtitle, points, footerText} = content

  return (
      <ContentWrap>
        <MobileColored>
          <Title>
            {title}
          </Title>
          <Subtitle>
            {subtitle}
          </Subtitle>
          <ListWrap>
            {points.map((point, index) => <ListItem key={`point-${index}`}><Dot /><ListItemText>{point}</ListItemText></ListItem>)}
            <FooterText>
              {footerText}
            </FooterText>
          </ListWrap>
        </MobileColored>
      </ContentWrap>
  )
}

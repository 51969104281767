import React from 'react'
import styled from 'styled-components'
import uniqid from 'uniqid'

import { useMenuProvider } from './menu/MenuProvider'

const ButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 86px;
  width: 100%;
  padding: 0 18vw;
  z-index: 99;

  @media (max-width: 625px) {
    justify-content: space-evenly;
    gap: 10px;
    bottom: 25px;
    padding: 0;
  }
`
const Button = styled.button.attrs(({ blue }) => ({
  style: {
    backgroundColor: blue ? '#96C5FF' : '#FC26BC',
  }
}))`
  text-align: center;
  height: 86px;
  border-radius: 20px;
  padding: 10px 20px;
  font: var(--font_6);
  font-size: 28px;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.6);
  border: none;
  color: white;
  cursor: pointer;

  @media (max-width: 768px) {
    height: 66px;
    font: var(--font_7);
  }

  @media (max-width: 450px) {
    width: fit-content;
    min-width: 132px;
    font-size: 18px;
    height: 46px;
    padding: 8px 10px;
    border-radius: 10px;
  }
`

const Buttons = ({ buttons }) => {
  const { handleAppNavigate } = useMenuProvider()

  return (
    <>
      {
        buttons.map((button, index) => (
          <Button key={uniqid()} onClick={() => handleAppNavigate(button)} blue={index !== 0}>{button.text}</Button>
        ))
      }
    </>
  )
}

export default Buttons;

import React, { useCallback, useRef, useState } from 'react'
import styled from 'styled-components'

import { useMenuProvider } from '../../components/menu/MenuProvider'
import { Player } from '../../components/player/Player'

import ImgHome from '../../assets/images/first_bg.jpg'
import ImgContacts from '../../assets/images/city.jpeg'
import { audioData } from '../../assets/content/audio/audio'
import { sections, homeSectionsData, MainPageButtons } from '../../assets/content/info/texts'
import {
  StyledSection,
  UnColoredBackground,
  SectionsContainer,
  SectionsScrolledWrap
} from '../../components/sections'
import Buttons from '../../components/Buttons'
import { ContactsSection } from './components/ContactsSection'
import { MainSection } from './components/MainSection'

const Container = styled(SectionsContainer)`
  background-image: url('../../assets/images/first_bg.jpg');
`

const ButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 86px;
  width: 100%;
  padding: 0 18vw;
  z-index: 99;

  @media (max-width: 625px) {
    justify-content: space-evenly;
    gap: 10px;
    bottom: 25px;
    padding: 0;
  }
`

const Main = () => {
  const { isMenuOpen } = useMenuProvider()
  const audioRef = useRef()
  const {file} = audioData

  const [trackProgress, setTrackProgress] = useState(0);
  const handleTimeChange = useCallback(() => setTrackProgress(audioRef.current.currentTime), [setTrackProgress])

  return (
    <>
      <audio
        preload='metadata'
        ref={audioRef}
        onTimeUpdate={handleTimeChange}
      >
        <source src={file} type="audio/mpeg"/>
      </audio>

      <Player
        audioRef={audioRef}
        trackProgress={trackProgress}
        setTrackProgress={setTrackProgress}
        isMenuOpen={isMenuOpen}
      />

      <Container>
        <SectionsScrolledWrap id='scroll'>
          <StyledSection id={sections.HOME} height={800} backgroundImage={ImgHome}>
            <UnColoredBackground backgroundColor="rgba(14, 32, 150, 0.1)">
              <MainSection content={homeSectionsData[sections.HOME]} />
            </UnColoredBackground>
            <ButtonWrap>
              <Buttons buttons={MainPageButtons} />
            </ButtonWrap>
          </StyledSection>
          <StyledSection id={sections.CONTACT} height={618} backgroundImage={ImgContacts}>
            <UnColoredBackground backgroundColor="rgba(14, 32, 150, 0.3)">
              <ContactsSection content={homeSectionsData[sections.CONTACT]} />
            </UnColoredBackground>
          </StyledSection>
        </SectionsScrolledWrap>
      </Container>
    </>
  )
}

export default Main;

import React from 'react'

import { Sections } from '../../components/sections/index'
import { brandsSectionsUI } from '../../assets/content/info/texts'

const Brands = () => {
  return <Sections sections={brandsSectionsUI} />
}

export default Brands

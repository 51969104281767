import React from 'react'
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as TGIcon } from '../../assets/images/telegram-icon.svg'
import { ReactComponent as EIcon } from '../../assets/images/email-icon.svg'
import { ReactComponent as LiIcon } from '../../assets/images/linkedin-icon.svg'
import { ReactComponent as RedIcon } from '../../assets/images/reddit-icon.svg'
import { ReactComponent as InstIcon } from '../../assets/images/instagram-icon.svg'
import { ReactComponent as BackIcon } from '../../assets/images/back-icon.svg'

import { leftFabButtonText } from '../../assets/content/info/texts'
import { links } from '../../assets/content/info/links'
import { URLS } from '../../pages/urls'
import { useMenuProvider } from '../menu/MenuProvider'
import { Cookies } from './Cookies'

export const FabButton = styled.div`
  position: fixed;
  top: 56px;
  right: 60px;
  display: flex;
  z-index: 2;
  cursor: pointer;

  @media (max-width: 768px) {
    right: 3vw;
  }
`

const SocialButtons = styled(FabButton)`
  display: grid;
  gap: 12px;
  width: 33px;
  top: calc((100% - 92px) / 2);

  @media (max-width: 768px) {
    top: 100px;
  }
`
const SocialButton = styled.div`
  height: 25px;
  width: 25px;
  justify-content: center;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.6);
  border-radius: 50%;

  > a > svg {
    width: 25px;
    height: 25px;
    fill: #FFFFFF;
  }

  > a > svg:hover {
    fill: #00EAFA;
  }
`

const LeftButton = styled(FabButton)`
  left: 60px;
  color: #00EAFA;
  width: fit-content;
  cursor: auto;
  font-size: 26px;

  @media (max-width: 768px) {
    display: none;
  }
`

const BackButton = styled(LeftButton)`
  border-radius: 50%;
  cursor: pointer;
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  top: 56px;
  
  &:hover {
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.6);
  }

  @media (max-width: 768px) {
    top: 18px;
    left: 3vw;
  }
`


export const Fabs = () => {
  const { menuOpenButton } = useMenuProvider()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  return (
    <>
      <Cookies />

      {
        pathname === URLS.root()
          ? <LeftButton>{leftFabButtonText}</LeftButton>
          : pathname !== URLS.privacyPolicy() && pathname !== URLS.conditions() && (
            <BackButton onClick={() => navigate(URLS.root())}>
              <BackIcon width={44} height={44}/>
            </BackButton>
          )
      }

      { menuOpenButton }
      <SocialButtons>
        <SocialButton>
          <a href={`mailto:${links.email}`} target='_blank' rel="noreferrer">
            <EIcon />
          </a>
        </SocialButton>
        <SocialButton>
          <a href={links.reddit} target='_blank' rel="noreferrer">
            <RedIcon />
          </a>
        </SocialButton>
        <SocialButton>
          <a href={links.linkedIn} target='_blank' rel="noreferrer">
            <LiIcon />
          </a>
        </SocialButton>
        <SocialButton>
          <a href={links.instagram} target='_blank' rel="noreferrer">
            <InstIcon />
          </a>
        </SocialButton>
        <SocialButton>
          <a href={links.telegram} target='_blank' rel="noreferrer">
            <TGIcon />
          </a>
        </SocialButton>
      </SocialButtons>
    </>
  )
}

import React, { useRef, useEffect } from 'react'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import "./styles.css"

export const Video = ({ video, poster, onReady, affiliates }) => {
  const videoRef = useRef(null)
  const playerRef = useRef(null)

  useEffect(() => {
    if (!playerRef.current) {
      const videoElement = videoRef.current
      if (!videoElement) return
      const player = playerRef.current =
        videojs(videoElement, {
          controls: true,
          fill: true,
          fluid: true,
          sources: [{
            src: video,
            type: 'video/mp4'
          }]
        }, () => {
          videojs.log('Video player is ready')
          onReady && onReady(player)
        })
      player.poster(poster)
    }
  }, [videoRef])

  useEffect(() => {
    const player = playerRef.current
    return () => {
      if (player) {
        player.dispose()
        playerRef.current = null
      }
    };
  }, [playerRef])

  return (
    <div data-vjs-player>
      <video id="video" ref={videoRef} className={`video-js vjs-big-play-button ${affiliates ? 'affiliates-size' : 'brands-size'}`} playsInline/>
    </div>
  )
}

export default Video;

import React, { useCallback } from 'react'
import styled from "styled-components"
import uniqid from 'uniqid'
import {Link} from 'react-router-dom'
import { textTypes } from '../../assets/content/info/conditions'
import { URLS } from '../urls'

const Container = styled.section`
  padding: 60px;
  color: white;
  background-color:  #0e2096;
  white-space: pre-line;
  font-size: 11px;

  h4, h2, p, li {
    font-family: Arial ,sans-serif;
    font-size: 11px;
  }
  
  > h4 {
    margin-top: 30px;
    font-weight: bold;
  }

  > h2 {
    font-size: 14px;
  }
  
  > p, ul {
    margin-top: 10px;
  }
  
  li {
    margin-bottom: 10px;
    font-weight: 400;
  }
  
  a, a:visited {
    color: #00EAFA;
  }

  @media (max-width: 768px) {
    padding: 20px;
  }
`

const Header = ({children}) => <h2>{children}</h2>
const SubHeader = ({children}) => <h4>{children}</h4>
const Paragraph = ({ children }) => (
  <p>
    {children.bold && typeof children.bold === 'string' && <b>{children.bold}</b>}
    {children.text || children}
    {children.link && typeof children.link === 'string' && <Link to={URLS.privacyPolicy()} target="_blank" rel="noopener noreferrer">{children.link}</Link>}
  </p>
)
const OrderedList = ({children}) => (
    <ol>
      {Object.values(children).map((li) => (
          <li key={uniqid()}>
            <b>{li.name}</b>
            {li.info}
          </li>
      ))}
    </ol>
)

const UnorderedList = ({children}) => (
    <ul>
      {Object.values(children).map((li) => (
          <li key={uniqid()}>
            <b>{li.name}</b>
            {li.link && <Link to={URLS.privacyPolicy()} target="_blank" rel="noopener noreferrer">{li.link}</Link>}
            {li.info}
          </li>
      ))}
    </ul>
)
const ComplexLists = ({children}) => (
    <ol>
      {Object.values(children).map((li) => (
          <li key={uniqid()}>
            <b>{li.name}</b>
            <p>{li.info}</p>
            <UnorderedList>{li.content}</UnorderedList>
          </li>
      ))}
    </ol>
)

const Conditions = ({ content }) => {
  const renderItem = useCallback((item) => {
    const textComponentTypes = {
      [textTypes.header]: Header,
      [textTypes.subheader]: SubHeader,
      [textTypes.paragraph]: Paragraph,
      [textTypes.orderedList]: OrderedList,
      [textTypes.unorderedList]: UnorderedList,
      [textTypes.unorderedListInsideOrdered]: ComplexLists
    }

    const Component = textComponentTypes[item.textType]

    return <Component key={uniqid()} children={item.content} />
  }, [])


  return (
      <Container>
        {content.map(renderItem)}
      </Container>
  )
}

export default Conditions;

import React, { useCallback, useRef } from "react";
import styled from "styled-components";
import Video from '../../../components/video/Video'

const VideoContainer = styled.div`
  width: 100%;
  max-width: 100% !important;
  height: fit-content;
  overflow: hidden;
  
  > iframe {
    border: none;
  }
`

export const VideoSection = ({ content }) => {
  const playerRef = useRef(null)
  const handlePlayerReady = useCallback((player) => playerRef.current = player,[playerRef.current])

  return (
    <VideoContainer>
      <Video onReady={handlePlayerReady} video={content.video} poster={content.poster} affiliates/>
    </VideoContainer>
  )
}

import React from 'react'
import styled from 'styled-components'

import MenuBg from '../../assets/images/menu_bubbles.png'
import { sections } from '../../assets/content/info/texts'
import { ReactComponent as CloseIcon } from '../../assets/images/close.svg'

const StyledMenu = styled.section `
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  background: #0e2096;
  z-index: 5;
  overflow: hidden;

  @media (max-width: 425px) {
    background: rgb(0,234,250);
  }
`
const CirclesTypes = {
  big: "big",
  medium: 'medium',
  small: 'small'
}
const CircleSize = {
  [CirclesTypes.small]: "535px",
  [CirclesTypes.medium]: "665px",
  [CirclesTypes.big]: "795px"
}
const CircleOpacity = {
  [CirclesTypes.small]: "0.5",
  [CirclesTypes.medium]: "0.2",
  [CirclesTypes.big]: "0.08"
}

const Circle = styled.div`
  border-radius: 50%;
  border: ${({circle}) => `3px solid rgba(0, 234, 250, ${CircleOpacity[circle]})`};
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({circle}) => CircleSize[circle]};
  height: ${({circle}) => CircleSize[circle]};
  position: absolute;
  z-index: 2;

  @media (max-width: 425px) {
    display: none;
  }
`

const Container = styled.div`
  background-color: rgb(0,234,250);

  width: 100%;
  max-width: 997px;
  height: 471px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  text-align: center;
  line-height: 52px;
  font-weight: bold;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.6);
  overflow-y: hidden;
  z-index: 6;
  
  @media (min-width: 426px) {
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1.5s;
  }

  @media (max-width: 425px) {
    height: 100vh;
    display: block;
    padding: 60px 0;
  }

  @-webkit-keyframes fadeIn {
    from { opacity: 0; margin-left: -60px }
    to { opacity: 1; margin-left: 0 }
  }
  @keyframes fadeIn {
    from { opacity: 0; margin-left: -60px }
    to { opacity: 1; margin-left: 0 }
  }
`

const BubblesAnimation = styled(Container)`
  height: 100vh;
  background-color: transparent;
  box-shadow: none;
  width: 100vw;
  
  background-image: ${({img}) => img ? `url(${img})` : 'none'};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center 48%;
  position: absolute;

  @media (min-width: 426px) {
    -webkit-animation: MoveUpDown 4s linear infinite;
    animation: MoveUpDown 4s linear infinite;
  }

  @-webkit-keyframes MoveUpDown {
    0%, 100% {
      transform: scale(1.2) translateY(0);
    }
    50% {
      transform: scale(1.25, 1.2) translateY(10px);
    }
  }
  
  @keyframes MoveUpDown {
    0%, 100% {
      transform: scale(1.2) translateY(0);
    }
    50% {
      transform: scale(1.25, 1.2) translateY(10px);
    }
  }
`
const CloseButton = styled.div`
  color: rgb(12, 28, 148);
  position: absolute;
  top: 20px;
  right: 25px;
  cursor: pointer;
  
  > svg {
    display: block;
    width: 27px;
    height: 27px;
    fill: #0e2096;
  }

  @media (max-width: 425px) {
    position: relative;
    direction: rtl;
  }
`
const SectionsList = styled.ul`
  display: grid;
  justify-content: center;
  list-style: none;
  padding-inline-start: 0;
`
const Section = styled.li`
  > div {
    display: inline-block;
    width: 100%;
    color: ${({id}) => id === sections.HOME ? `white` : `rgb(12, 28, 148)`};
    cursor: pointer;

    :-webkit-any-link {
      color: ${({id}) => id === sections.HOME ? `white` : `rgb(12, 28, 148)`};
    }

    :hover {
      color: white;
    }

    @media (max-width: 425px) {
      border-bottom: 1px solid rgb(12, 28, 148);
    }
  }
`

export const Menu = ({ items, closeMenu, handleClick }) => {
  return (
    <div style={{ position: 'absolute', width: '100%', height: '100%', overflow: 'hidden !important'}}>
      <StyledMenu img={MenuBg}>
        <Circle circle={CirclesTypes.big}>
          <Circle circle={CirclesTypes.medium}>
            <Circle circle={CirclesTypes.small}/>
          </Circle>
        </Circle>
        <BubblesAnimation img={MenuBg}/>
        <Container>
          <CloseButton onClick={closeMenu}><CloseIcon /></CloseButton>
          <SectionsList>
            {
              items.map((item) => {
                const click = () => handleClick(item)
                return (
                  <Section key={item.id}>
                    <div onClick={click}>{item.id}</div>
                  </Section>
                )
              })
            }
          </SectionsList>
        </Container>
      </StyledMenu>
    </div>
  )
}

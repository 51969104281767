import React, { useCallback, useMemo, useState } from 'react'
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { Menu } from './Menu'
import { FabButton, Fabs } from '../fabs/Fabs'
import { URLS } from '../../pages/urls'

const MenuButton = styled(FabButton)`
  gap: 4px;
  top: 56px;
  :hover > div > span {
    background-color: #FFFFFF;
  }

  @media (max-width: 768px) {
    top: 25px;
  }
`
const DotRow = styled.div`
  display: grid;
  gap: 4px;
  
`
const Dot = styled.span`
  background-color: #00EAFA;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.6);
`

export const MenuProviderContext = React.createContext({
  isMenuOpen: false,
  menu: <></>,
  menuOpenButton: <></>,
})

export function useMenuProvider() {
  return React.useContext(MenuProviderContext)
}

const rootMenu = [
  { id: 'HOME', url: URLS.root(), scroll: true },
  { id: 'BRANDS', url: URLS.brands() },
  { id: 'AFFILIATES', url: URLS.affiliates() },
  { id: 'CONTACT', url: URLS.root(), scroll: true },
]

export default function MenuProvider({ children, handleScroll }) {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const closeMenu = useCallback(() => setIsMenuOpen(false), [])
  const openMenu = useCallback(() => setIsMenuOpen(true), [])

  const handleClick = useCallback((item) => {
    if (item.url) {
      closeMenu()
      navigate(item.url)
      if (item.scroll) handleScroll(item.id)
    } else {
      closeMenu()
      handleScroll(item.id)
    }
  }, [navigate, closeMenu, handleScroll])

  const handleAppNavigate = useCallback((item) => {
    if (item.url) {
      navigate(item.url)
      if (item.scroll) handleScroll(item.id)
    } else {
      handleScroll(item.id)
    }
  }, [navigate, handleScroll])

  const menu = useMemo(() => (
    <Menu
      closeMenu={closeMenu}
      handleClick={handleClick}
      items={rootMenu}
    />
  ), [closeMenu, handleClick, pathname])

  const menuOpenButton = useMemo(() => (
    <MenuButton onClick={openMenu}>
      <DotRow><Dot/><Dot/><Dot/></DotRow>
      <DotRow><Dot/><Dot/><Dot/></DotRow>
      <DotRow><Dot/><Dot/><Dot/></DotRow>
    </MenuButton>
  ), [openMenu])

  const value = useMemo(() => ({
    isMenuOpen,
    menu,
    menuOpenButton,
    handleAppNavigate,
  }), [isMenuOpen, menu, menuOpenButton, handleAppNavigate])

  return (
    <MenuProviderContext.Provider value={value}>
      {isMenuOpen && menu }
      <Fabs />
      {children}
    </MenuProviderContext.Provider>
  )
}

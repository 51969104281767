export const links = {
  // links - to change - just change text below
  // ссылки - для изменения просто измените только текст, который находится внутри '' скобок,
  // не меняя ключевое слово слева
  telegram: 'https://t.me/+U-DtSIwjl54yMzg0',
  email: 'info@gramatik.space',
  reddit: 'https://www.reddit.com/r/GramatikCPA/',
  linkedIn: 'https://www.linkedin.com/company/92554146/',
  instagram: 'https://www.instagram.com/gramatikcpa?igsh=bG43cjFqcjVyNTB2',
  // BRANDS PAGE ADDITIONAL LINKS
  broker_rating: 'https://sky-tide.com/',
  reddit_gramatik_insights: 'https://www.reddit.com/r/XGramatikInsights/'
}

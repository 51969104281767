import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { PARTNERS, PARTNERS_LOGO } from '../../../assets/content/info/texts'

const ContentWrap = styled.div`
  flex-direction: column;
  display: flex;
  justify-items: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.15em;
  max-width: 100% !important;
  height: 90%;
  padding: 40px 40px 60px;
  justify-content: space-between;

  @media (max-width: 450px) {
    height: 82%;
    padding: 0;
  }
`
const MobileColored = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  @media (max-width: 450px) {
    background: rgba(14, 32, 150, 0.6);
  }
`

const Title = styled.h3`
  line-height: 1.4em;
  color: #00EAFA;
  font-size: 30px;
  margin-top: 25px;

  @media (max-width: 768px) {
    margin-top: 20px;
  }
`
const SubTitle = styled.h4`
  font-size: 28px;
  margin-top: 12px;

  > a {
    color: #00EAFA;
  }
  > a:visited {
    color: #00EAFA;
  }
  > a:hover {
    color: #FFFFFF;
  }

  @media (max-width: 768px) {
    margin-top: 6px;
  }
`
const Description = styled.h5`
  font-size: 24px;
  margin-top: 12px;

  @media (max-width: 768px) {
    margin-top: 6px;
  }
`
const DataRow = styled.div`
  display: flex;
  width: 98%;
  flex-wrap: wrap;
`
const DataElement = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 25%;
  text-align: center;
  align-items: center;

  @media (max-width: 768px) {
    width: 33%;
    padding: 0;
    margin-top: 30px;
  }

  @media (max-width: 450px) {
    width: 50%;
  }
`

const CommonIcon = styled.div`
  width: 160px;
  height: 160px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: ${({img}) => `url(${img})`};
  background-color: ${({black}) => black ? 'black' : 'white'};
  border-radius: 20%;

  @media (max-width: 450px) {
    width: 80px;
    height: 80px;
  }
`

const IconWrap = styled.div`
  padding: 8px;
  border-radius: 20%;
  background-color: ${({black}) => black ? 'black' : 'white'};
  box-shadow: 0 3px 10px 1px rgba(0,0,0,0.4);
`

const DataHeader = styled.p`
  font-size: 21px;
  margin: 20px 0;
  text-transform: uppercase;

  @media (max-width: 450px) {
    font-size: 14px;
    margin: 10px 0;
  }
`

const Logo = ({ el }) => {
  const isItBlackIcon = [PARTNERS.blackbull, PARTNERS.okx].includes(el)
  const img = PARTNERS_LOGO[el]
  return <IconWrap black={isItBlackIcon}><CommonIcon img={img} black={isItBlackIcon}/></IconWrap>
}

export const ServicesSection = ({ content }) => {
  const { title, subtitle, subtitleLink, description, partners } = content
  return (
    <ContentWrap>
      <MobileColored>
      <Title>
        {title}
      </Title>
        <SubTitle>
          <Link to={subtitleLink} target='_blank' rel="noreferrer" >{subtitle}</Link>
        </SubTitle>
        <Description>
          {description}
        </Description>
      <DataRow>
        {
          partners.map((el) => (
            <DataElement key={el}>
              <Logo el={el} />
              <DataHeader>{el}</DataHeader>
            </DataElement>
          ))
        }
      </DataRow>
        </MobileColored>
    </ContentWrap>
  )
}

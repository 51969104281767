import React, { useEffect, useState, useCallback } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { termsAndConditionsTexts, privacyPolicyTexts } from './assets/content/info/conditions'

import MenuProvider from './components/menu/MenuProvider'
import Affiliates from './pages/affiliates/Affiliates'
import Brands from './pages/brands/Brands'
import Conditions from './pages/conditions/Conditions'
import Main from './pages/main/Main'
import { URLS } from './pages/urls'

import './App.css'

const App = () => {
  const [scrollTo, setScrollTo] = useState(undefined)

  const handleScroll = useCallback((id) => setScrollTo(id), [])

  useEffect(() => {
    if (scrollTo) {
      const element = document.getElementById(scrollTo);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
      setScrollTo(undefined)
    }
  }, [scrollTo])

  return (
    <BrowserRouter>
      <MenuProvider handleScroll={handleScroll}>
        <Routes>
          <Route path={URLS.conditions()} element={<Conditions content={termsAndConditionsTexts} />}/>
          <Route path={URLS.privacyPolicy()} element={<Conditions content={privacyPolicyTexts} />}/>
          <Route path={URLS.affiliates()} element={<Affiliates />} />
          <Route path={URLS.brands()} element={<Brands />} />
          <Route path={URLS.root()} element={<Main />} />
        </Routes>
      </MenuProvider>
    </BrowserRouter>
  )
}

export default App;

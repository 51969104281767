// Данные по секциям. Все тексты нужно менять здесь
// The section data. All of it must changed here below

import { ContactFormSection } from '../../../components/sections/ContactFormSection'
import { ProjectSection } from '../../../pages/brands/components/ProjectSection'
import { ServicesSection } from '../../../pages/brands/components/ServicesSection'
import { AboutSection } from '../../../pages/brands/components/AboutSection'
import { AboutSection as AffiliatesAboutSection } from '../../../pages/affiliates/components/AboutSection'
import { ProductsSection } from '../../../pages/affiliates/components/ProductsSection'
import { TeamSection } from '../../../pages/affiliates/components/TeamSection'
import { VideoSection } from '../../../pages/affiliates/components/VideoSection'
import { ServicesSection as AffiliatesServicesSection } from '../../../pages/affiliates/components/ServicesSection'
import { URLS } from '../../../pages/urls'

import BrandsVideo from '../../video/Crowd.mp4'
import AffiliatesVideo from '../../video/Promo.mp4'
import BrandsPoster from '../../images/video-poster.png'
import AffiliatesPoster from '../../images/affiliatesPoster.jpeg'

import ChangeImg4 from '../../images/bubbles_bg.png'
import ChangeImg2 from '../../images/change_second_bg.png'
import ImgTeam from '../../images/fifth_bg.jpg'
import ImgServices from '../../images/fourth_bg.jpg'
import ChangeImg3 from '../../images/gradient_bg.png'
import FirstPoster from "../../images/poster_1.jpg";
import SecondPoster from "../../images/poster_2.jpg";
import ThirdPoster from "../../images/poster_3.jpg";
// При наведении на изображение колонки картинка меняется
// image changes on hover the column image
import FirstChangedPoster from "../../images/poster_1_change.png";
import SecondChangedPoster from "../../images/poster_2_change.png";
import ThirdChangedPoster from "../../images/poster_3_change.png";

// Icons for SERVICES section columns
// Иконки для колонок секции SERVICES
import EyeIcon from "../../images/eye_icon.png";
import PenIcon from "../../images/icon_pen.png";
import QuotesIcon from "../../images/icon_quotes.png";
import ImgAbout from '../../images/second_bg.png'
import ImgProjects from '../../images/third_bg.jpg'
// Partners logos
import PepperstoneLogo from '../../images/pepperstone.svg'
import AvatradeLogo from '../../images/avatrade.jpeg'
import EtoroLogo from '../../images/etoro.svg'
import ExnessLogo from '../../images/exness.svg'
import IntertraderLogo from '../../images/intertrader.png'
import OkxLogo from '../../images/okx.jpg'
import YouhodlerLogo from '../../images/youhodler.png'
import BlackbullLogo from '../../images/blackbull.png'

import {links} from "./links";

// Icons for PROJECTS section columns
import FirstBrandsPoster from "../../images/reddit-poster.png";
import FirstChangedBrandsPoster from "../../images/skytide-poster.png";
import SecondBrandsPoster from "../../images/lending-poster.png";

// Название секций и порядок отображения для окна меню
// Sections names and display order for menu window
export const sections = {
  HOME: "HOME",
  ABOUT: "ABOUT",
  PROJECTS: "PROJECTS",
  SERVICES: "SERVICES",
  THE_TEAM: "THE TEAM",
  CLIENTS: "CLIENTS",
  CONTACT: "CONTACT",
  PROMO_VIDEO: "PROMO_VIDEO",
}


// MAIN PAGE data
export const leftFabButtonText = 'CPA'
export const homeSectionsData = {
  [sections.HOME]: {
    title: 'Gramatik',
    text: 'FIRST CLASS WH TRAFFIC ONLY \n EXCLUSIVE OFFERS & FUNNELS \n \n WE ADD VALUE ',
  },
  [sections.CONTACT]: {
    column_1: {
      header: 'WE WORK FOR YOU',
      text: '24/7',
    },
    column_2: {
      header: 'GET IN TOUCH',
      email: `${links.email}`,
      redditLink: `${links.reddit}`,
      redditTextLink: 'Reddit',
      linkedInLink: `${links.linkedIn}`,
      linkedInTextLink: 'LinkedIn',
      instagramLink: `${links.instagram}`,
      instagramTextLink: 'Instagram',
      telegramLink: `${links.telegram}`,
      telegramTextLink: 'Telegram',
    },
    header: 'Top-notch marketing solutions \n for top-notched digital marketing professionals',
    text: 'www.gramatik.site is a trading name of Gramatik Ltd. with registration number 516772829 \n and address Arik Einstein 3, Herzliya 4659071 Israel',
    privacyPolicy: 'Privacy Policy',
    termsAndConditions: 'Terms and Conditions',
  },
}
export const MainPageButtons = [{
  text: 'To BRANDS',
  url: URLS.brands()
 }, {
  text: 'To AFFILIATES',
  url: URLS.affiliates()
}]

// AFFILIATES PAGE data
export const AffiliatesPageButtons = [{
  text: 'Contact us',
  id: sections.CLIENTS
}, {
  text: 'Watch the video',
  id: sections.PROMO_VIDEO
}]
export const affiliatesSectionsUI = {
  0: {
    id: sections.ABOUT,
    height: 800,
    minHeight: 700,
    Component: AffiliatesAboutSection,
    backgroundImage: ImgAbout,
    backgroundColor: 'rgba(14, 32, 150, 0.2)',
    changeImg: ChangeImg2,
    content: {
      title: 'YOU WILL SEE THE RESULT \n ONLY IF YOU KNOW THE PRODUCT \n YOUR HIGH CONVERSION - IN OUR HANDS',
      column_1: {
        header: '95%',
        text: 'Ad campaign approval \n rate'
      },
      column_2: {
        header: '120+',
        text: 'Available GEOs'
      },
      column_3: {
        header: '50+',
        subheader: 'REGULATED',
        text: 'Forex/Crypto Brokers'
      },
    },
  },
  1: {
    id: sections.PROMO_VIDEO,
    height: 810,
    minHeight: 250,
    maxHeight: 'fit-content',
    Component: VideoSection,
    backgroundColor: '#000000',
    content: {
      video: AffiliatesVideo,
      poster: AffiliatesPoster,
    }
  },
  2: {
    id: sections.PROJECTS,
    height: 1622,
    Component: ProductsSection,
    backgroundImage: ImgProjects,
    backgroundColor: 'rgba(14, 32, 150, 0.3)',
    changeImg: ChangeImg3,
    content: {
      title_1: 'WHITE FOREX/CRYPTO PRODUCTS',
      title_2: 'THIS IS ALWAYS A HIGHER CONVERSION RATE',
      column_1: {
        header: 'CALL CENTER / \n SUPPORT / KYC',
        text: "Communicate with your leads in a gentle and professional manner.",
        img: FirstPoster,
        changeImg: FirstChangedPoster,
      },
      column_2: {
        header: 'BRANDS DO NOT DISAPPEAR',
        text: "Customer's funds remain \n Customer's funds",
        img: SecondPoster,
        changeImg: SecondChangedPoster,
      },
      column_3: {
        header: 'NO FAILURES OF \n PAYMENT SYSTEMS',
        img: ThirdPoster,
        changeImg: ThirdChangedPoster,
      },
    },
  },
  3: {
    id: sections.SERVICES,
    height: 898,
    Component: AffiliatesServicesSection,
    backgroundImage: ImgServices,
    backgroundColor: 'rgba(14, 32, 150, 0.6)',
    changeImg: ChangeImg4,
    content: {
      title: 'REMEMBER: \n THERE IS MORE TRAFFIC AT THE MARKET \n THAN THOSE WHO CAN ACCEPT IT!',
      subtitle: 'Generate only what turns into FTD',
      columns: [{
        header: 'TARGETING',
        text: 'Get precise targeting for each GEO for each Broker  from us',
        img: EyeIcon,
      }, {
        header: 'HIGH \n POTENTIAL \n FUNNEL',
        text: 'Funnels with proven conversion only',
        img: PenIcon,
      }, {
        header: 'FINE TUNING',
        text: 'Proper feedback will help you set up an advertising campaign down to microns',
        img: QuotesIcon,
      }]
    },
  },
  4: {
    id: sections.THE_TEAM,
    height: 688,
    Component: TeamSection,
    backgroundImage: ImgTeam,
    backgroundColor: `radial-gradient(circle at 50% 50%, rgba(228, 235, 252, 0.32) 0%, rgba(88, 155, 227, 0.32) 0%, rgba(0, 47, 94, 0.7) 84.3751311302185%, rgba(0, 0, 0, 0.32) 100%)`,
    content: {
      title: 'IMPORTANT:',
      paragraph1: 'Lead generation is a modern and extremely high-income field. Potentially a high profitability might often be achieved by using anti-moral advertising funnels. We DO NOT ACCEPT traffic, leads generated by fraudulent funnels that have nothing to do with financial products.',
      paragraph2: 'Any used prelander and landing page must fully comply with the requirements imposed by the Financial Commissions, regional Advertising Laws and not be in dissonance with the general human principles of morality.',
      paragraph3: 'Remember, only by understanding the advertised product and working with high-end regulated financial products can you achieve truly amazing financial results.',
    },
  },
  5: {
    id: sections.CLIENTS,
    height: 434,
    Component: ContactFormSection,
    backgroundColor: '#0e2096',
    content: {
      title: 'CONTACT US',
      button: 'Submit',
    },
  }
}

// BRANDS PAGE data
export const PARTNERS = {
  pepperstone: 'Pepperstone',
  blackbull: 'BlackBull',
  avatrade: 'AvaTrade',
  etoro: 'eToro',
  exness: 'Exness',
  intertrader: 'Intertrader',
  okx: 'OKX',
  youhodler: 'YouHodler',
}
export const PARTNERS_LOGO = {
  [PARTNERS.pepperstone]: PepperstoneLogo,
  [PARTNERS.blackbull]: BlackbullLogo,
  [PARTNERS.avatrade]: AvatradeLogo,
  [PARTNERS.etoro]: EtoroLogo,
  [PARTNERS.exness]: ExnessLogo,
  [PARTNERS.intertrader]: IntertraderLogo,
  [PARTNERS.okx]: OkxLogo,
  [PARTNERS.youhodler]: YouhodlerLogo,
}
export const brandsSectionsUI = {
  0: {
    id: sections.ABOUT,
    height: 800,
    Component: AboutSection,
    backgroundImage: ImgAbout,
    backgroundColor: 'rgba(14, 32, 150, 0.2)',
    changeImg: ChangeImg2,
    content: {
      title: 'WHY GRAMATIK?',
      subtitle: 'We know the Product. We know the Client.\n \n',
      points: [
        'High-quality WH in-house & affiliate traffic',
        'Wide range of trusted sources',
        'Full compliance with the regulatory & advertising policies',
        'Pre-approved campaigns only',
        'Expert assessment Integration types: referral links / API/ postbacks / AppsFlyer',
      ],
      footerText: 'Integration types: referral links/ API / postbacks /AppsFlyer'
    },
  },
  1: {
    id: sections.PROJECTS,
    height: 1622,
    Component: ProjectSection,
    backgroundImage: ImgProjects,
    backgroundColor: 'rgba(14, 32, 150, 0.3)',
    changeImg: ChangeImg3,
    content: {
      video: BrandsVideo,
      poster: BrandsPoster,
      title_1: 'MAIN VERTICALS: Forex, Crypto, FinTech',
      title_2: 'GEOS AVAILABLE AS PER BRAND’s REGULATION \n EXCLUSIVE IN-HOUSE SOURCES & FUNNELS:',
      column_1: {
        header: 'ORGANIC TRAFFIC',
        links: [{
          before: "Website: ",
          link_text: "Broker Rating",
          link: links.broker_rating,
        },
        {
          before: "Social Media: ",
          link_text: "Reddit",
          link: links.reddit_gramatik_insights,
        }],
        img: FirstBrandsPoster,
        changeImg: FirstChangedBrandsPoster,
      },
      column_2: {
        header: 'NATIVE ADVERTISING',
        text: "Official Company Ads Accounts: Facebook Ads, Reddit Ads, DSP's",
        img: SecondBrandsPoster,
      },
      column_3: {
        header: 'MOBILE APPS TRAFFIC \n via AppsFlyer',
        img: ThirdPoster,
        changeImg: ThirdChangedPoster,
      },
    },
  },
  2: {
    id: sections.SERVICES,
    height: 898,
    Component: ServicesSection,
    backgroundImage: ImgServices,
    backgroundColor: 'rgba(14, 32, 150, 0.6)',
    changeImg: ChangeImg4,
    content: {
      title: 'THE REMARKABLE BRANDS WE FOCUS ON',
      subtitle: 'Best Forex & Crypto Platforms 2024',
      subtitleLink: links.broker_rating,
      description: 'Trusted, Regulated, Committed to making a difference',
      partners: Object.values(PARTNERS),
    },
  },
  4: {
    id: sections.CLIENTS,
    height: 434,
    Component: ContactFormSection,
    backgroundColor: '#0e2096',
    content: {
      title: 'CONTACT US',
      button: 'Submit',
    },
  },
}

import React from "react";
import styled from "styled-components";
import { AffiliatesPageButtons } from '../../../assets/content/info/texts'
import Buttons from '../../../components/Buttons'

const ContentWrap = styled.div`
  flex-direction: column;
  display: flex;
  justify-items: center;
  justify-content: space-between;
  align-items: center;
  padding: 62px 0 30px;
  height: 100%;

  @media (max-width: 768px) {
    justify-content: center;
  }
`
const Title = styled.h5`
  letter-spacing: 0.1em;
  text-align: center;
  font-size: 32px;
`
const DataRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90vw;
  padding-top: 10px;

  @media (max-width: 425px) {
    padding-top: 0;
  }
`
const DataElement = styled.div`
  display: grid;
  flex-grow: 1;
  text-align: center;
`
const DataHeader = styled.h3`
  margin: 20px 0 0;
  font-size: ${({index}) => index === 1 && '63px'};
  
  @media (max-width: 425px) {
    font-size: 37px;
  }
`
const DataText = styled.p`
  line-height: 1.4em;
  text-align: center;
`

const MobileColored = styled.div`
  @media (max-width: 768px) {
    background-color: rgba(14, 32, 150, 0.6);
    padding: 20px;
  }
`

const ButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 18vw;
  z-index: 99;

  @media (max-width: 768px) {
    margin-top: 20px;
  }

  @media (max-width: 515px) {
    justify-content: space-evenly;
    gap: 10px;
    padding: 0;
  }
`

export const AboutSection = ({ content }) => {
  const {title, column_1, column_2, column_3} = content

  return (
      <ContentWrap>
        <MobileColored>
          <Title>
            {title}
          </Title>
        </MobileColored>
        <DataRow>
          {
            [column_1, column_2, column_3].map((element, index) => (
                <DataElement key={element.header}>
                  <DataHeader index={index}>{element.header}</DataHeader>
                  <DataText>{element.subheader && <>{element.subheader}<br/></>}{element.text}</DataText>
                </DataElement>
            ))
          }
        </DataRow>
        <ButtonWrap>
          <Buttons buttons={AffiliatesPageButtons} />
        </ButtonWrap>
      </ContentWrap>
  )
}
